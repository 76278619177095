const settings = {
    //dev: "http://localhost:3010/api",
    dev: "http://localhost:3010/api",
    demo: "https://ste-social-demo.herokuapp.com/api",
    prod: "https://social.thdsafety.com/api",
};

const getBaseURL = () => {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
        return settings.dev;
    } else {
        return settings.demo;
    }
    //for production
    // return settings.prod;
};

const baseURL = getBaseURL();

export default baseURL;
