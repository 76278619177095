import React from "react";
import { Global } from "@emotion/react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import CloseDrawerButton from "./CloseDrawerButton";
import StickersPanel from "./StickersPanel";

const drawerBleeding = 40;

const Container = styled("div")({
    height: "100%",
    backgroundColor: "white",
});

const StyledBox = styled(Box)({
    position: "absolute",
    top: -drawerBleeding,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    visibility: "visible",
    right: 0,
    left: 0,
    height: drawerBleeding,
});

const Puller = styled(Box)({
    width: 100,
    height: 6,
    backgroundColor: "white",
    borderRadius: 3,
    position: "absolute",
    top: 8,
    left: "calc(50% - 50px)",
});

function StickerDrawer(props) {
    const { open, toggleDrawer, mobileScreen } = props;

    return (
        <Container>
            <Global
                styles={{
                    ".MuiDrawer-root > .MuiPaper-root": {
                        height: `calc(70% - ${drawerBleeding}px)`,
                        overflow: !open ? "visible" : "auto",
                        borderTopLeftRadius: 15,
                        borderTopRightRadius: 15,
                        backgroundColor: "rgba(0, 0, 0, 0.7)",
                    },
                }}
            />
            <SwipeableDrawer
                anchor="bottom"
                open={open}
                onClose={() => toggleDrawer(false)}
                onOpen={() => toggleDrawer(true)}
                swipeAreaWidth={drawerBleeding}
                disableSwipeToOpen={false}
                ModalProps={{
                    keepMounted: true,
                }}
                sx={{
                    "& .MuiBackdrop-root": {
                        backgroundColor: "initial",
                    },
                }}
            >
                <div>
                    <Puller />
                    {!mobileScreen ? <CloseDrawerButton onClick={() => toggleDrawer(false)} /> : null}
                    <StickersPanel toggleDrawer={toggleDrawer} />
                </div>
                {!open ? (
                    <StyledBox>
                        <Puller />
                    </StyledBox>
                ) : null}
            </SwipeableDrawer>
        </Container>
    );
}

export default StickerDrawer;
