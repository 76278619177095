import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import styled from "@emotion/styled";
import Button from "@mui/material/Button";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 240,
    color: "white",
    bgcolor: "rgba(0, 0, 0, 0.7)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px 0px",
    borderRadius: "10px",
};

const ShareButton = styled(Button)({
    margin: "3px 3px",
    width: "130px",
});

const Facebook = styled(ShareButton)({
    backgroundColor: "#2851A3",
});

const Twitter = styled(ShareButton)({
    backgroundColor: "#1D9BF0",
});

export default function ShareModal({ open, setOpen, share }) {
    const handleClose = () => setOpen(false);

    return (
        <Modal
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <Box sx={style}>
                    <div style={{ marginBottom: "10px" }}>Share Now!</div>
                    <Facebook variant="contained" onClick={() => share("Facebook")}>
                        Facebook
                    </Facebook>
                    <Twitter variant="contained" onClick={() => share("Twitter")}>
                        Twitter
                    </Twitter>
                </Box>
            </Fade>
        </Modal>
    );
}
