import { store } from "../store/store";
import { setScreenWidth, setMobileScreen } from "../store/manage";

const handleScreenSize = () => {
    const getScreenWidth = () => {
        let width;
        if (window.screen.width < window.innerWidth) {
            // for moble browsers
            width = window.screen.width;
        } else {
            width = window.innerWidth;
        }
        return width;
    };
    const screenWidth = getScreenWidth();
    store.dispatch(setScreenWidth(screenWidth));

    const checkMobile = (screenWidth) => {
        if (screenWidth < 650) {
            store.dispatch(setMobileScreen(true));
        } else {
            store.dispatch(setMobileScreen(false));
        }
    };
    checkMobile(screenWidth);

    window.onresize = () => {
        const screenWidth = getScreenWidth();
        checkMobile(screenWidth);
        store.dispatch(setScreenWidth(screenWidth));
    };
};

export default handleScreenSize;
