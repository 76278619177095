import React, { useEffect } from "react";
import { Image, Transformer } from "react-konva";
import useImage from "use-image";

function CanvasEditedImage({ width, height, imageObj, y, x, imageSelected, selectImage }) {
    const src = imageObj ? imageObj.src : null;
    const [image] = useImage(src);

    const shapeRef = React.useRef();
    const trRef = React.useRef();
    useEffect(() => {
        if (imageSelected) {
            trRef.current.nodes([shapeRef.current]);
            trRef.current.getLayer().batchDraw();
        }
    }, [imageSelected]);

    if (width && height) {
        return (
            <React.Fragment>
                <Image
                    image={image}
                    width={width}
                    height={height}
                    y={y}
                    x={x}
                    draggable={imageSelected}
                    onClick={selectImage}
                    onTap={selectImage}
                    ref={shapeRef}
                />
                {imageSelected && (
                    <Transformer
                        ref={trRef}
                        boundBoxFunc={(oldBox, newBox) => {
                            // limit resize
                            if (newBox.width < 5 || newBox.height < 5) {
                                return oldBox;
                            }
                            return newBox;
                        }}
                    />
                )}
            </React.Fragment>
        );
    }
    return null;
}

export default CanvasEditedImage;
