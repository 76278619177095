import React from "react";
import styled from "@emotion/styled";
import IconButton from "@mui/material/IconButton";

const HowToUseButtonStyled = styled.div({
    display: "flex",
    flexDirection: "column",
    marginBottom: "20px",
});
const MyButton = styled(IconButton)({});

function HowToUseButton({ onClick }) {
    return (
        <HowToUseButtonStyled>
            <MyButton onClick={onClick}>
                <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M17.7704 27.312C17.1117 27.312 16.6004 27.1213 16.2364 26.74C15.8897 26.3413 15.7164 25.7953 15.7164 25.102C15.7164 24.79 15.7684 24.348 15.8724 23.776C15.9937 23.204 16.1324 22.5627 16.2884 21.852C16.4617 21.124 16.635 20.37 16.8084 19.59C16.999 18.81 17.1637 18.056 17.3024 17.328C16.7304 17.38 16.1497 17.5187 15.5604 17.744L14.9884 16.678C15.6297 16.158 16.323 15.7507 17.0684 15.456C17.8137 15.144 18.4637 14.988 19.0184 14.988C19.6944 14.988 20.1884 15.1787 20.5004 15.56C20.8124 15.924 20.9684 16.3487 20.9684 16.834C20.9684 17.146 20.9164 17.562 20.8124 18.082C20.7084 18.602 20.5784 19.174 20.4224 19.798C20.2664 20.4047 20.1104 21.0027 19.9544 21.592C19.7984 22.1813 19.6597 22.71 19.5384 23.178C19.4344 23.6287 19.3824 23.958 19.3824 24.166C19.3824 24.5993 19.5557 24.816 19.9024 24.816C20.2144 24.816 20.5524 24.712 20.9164 24.504L21.5404 25.622C21.0724 26.1073 20.4744 26.5147 19.7464 26.844C19.0184 27.156 18.3597 27.312 17.7704 27.312ZM19.6164 13.09C19.027 13.09 18.5764 12.9427 18.2644 12.648C17.9524 12.336 17.7964 11.9287 17.7964 11.426C17.7964 10.906 17.9264 10.4553 18.1864 10.074C18.4464 9.69267 18.767 9.398 19.1484 9.19C19.5297 8.982 19.9024 8.878 20.2664 8.878C20.873 8.878 21.3324 9.034 21.6444 9.346C21.9564 9.658 22.1124 10.0653 22.1124 10.568C22.1124 11.088 21.9737 11.5387 21.6964 11.92C21.4364 12.284 21.1157 12.57 20.7344 12.778C20.353 12.986 19.9804 13.09 19.6164 13.09Z"
                        fill="white"
                    />
                    <circle cx="18.5" cy="18.5" r="17" stroke="white" strokeWidth="3" />
                </svg>
            </MyButton>
            How to use
        </HowToUseButtonStyled>
    );
}

export default HowToUseButton;
