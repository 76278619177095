import React from "react";
import styled from "@emotion/styled";
import { useDispatch, useSelector } from "react-redux";
import { addSelectedSticker } from "../store/imageHandling";
import reactImageSize from "react-image-size";

const StickersPanelStyled = styled.div({
    marginTop: "30px",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
    alignItems: "center",
});

const StickerDiv = styled.div(({ mobileScreen }) => {
    return {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: mobileScreen ? "75px" : "150px",
        height: mobileScreen ? "75px" : "150px",
        padding: "20px 20px",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "grey",
            borderRadius: "10px",
        },
    };
});

const MyImage = styled.img({
    maxWidth: "100%",
    maxHeight: "100%",
});

const arr1 = ["CautionCone.png", "CautionTape.png", "EveryDaySafe.gif", "EveryDaySafe.png", "FamilyFirst.png", "safety-first.gif", "2023-SAM-logo.png", "SAM_Logo.gif", "STE_2.png", "STE_Logo.gif"];
const arr2 = ["bomb.gif", "InFocus.gif", "JobWellDone.png", "World'sGreatest.gif", "YouAreAppreciated.png"];
const arr3 = ["Gloves.png", "Grip&Grab.gif", "Knick.gif", "LiftIt.png", "PartnerUp.png", "SafetyKnife.png", "ZoneOfSafety.gif"];
const arr4 = ["CaughtOrangeHanded.png", "Confetti.gif", "GotYourBack.gif", "SafetyBravo.gif"];

const getCurrentDate = (separator = "-") => {
    let newDate = new Date();
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    return `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date < 10 ? `0${date}` : `${date}`}`;
};

function StickersPanel({ toggleDrawer }) {
    const dispatch = useDispatch();
    const mobileScreen = useSelector((state) => state.manage.mobileScreen);

    const handleSelect = async (image) => {
        try {
            const { width, height } = await reactImageSize("../assets/" + image);
            const regex = /^.+\.(.+)$/;
            const extension = image.match(regex)[1].toLowerCase();
            const obj = {
                id: Date.now(),
                name: image,
                width,
                height,
                type: extension,
            };
            dispatch(addSelectedSticker(obj));
            toggleDrawer(false);
        } catch (err) {
            console.log(err);
        }
    };

    const showWeek2 = `${getCurrentDate() >= "2022-01-10"}`;
    const showWeek3 = `${getCurrentDate() >= "2022-01-16"}`;
    const showWeek4 = `${getCurrentDate() >= "2022-01-23"}`;

    //test
    // const showWeek2 = "true";
    // const showWeek3 = "true";
    // const showWeek4 = "true";
    //end test

    let filesArr;
    if (showWeek2 === "true") {
        filesArr = arr1.concat(arr2);
    }

    if (showWeek3 === "true") {
        filesArr = arr1.concat(arr2, arr3);
    }

    if (showWeek4 === "true") {
        filesArr = arr1.concat(arr2, arr3, arr4);
    }

    return (
        <StickersPanelStyled>
            {filesArr.map((image) => (
                <StickerDiv mobileScreen={mobileScreen} key={image}>
                    <MyImage src={"../assets/" + image} onClick={() => handleSelect(image)} draggable="false" />
                </StickerDiv>
            ))}
        </StickersPanelStyled>
    );
}

export default StickersPanel;
