import client from "./client";

const sendImage = (formData) => {
    return client
        .post("/sendImage", formData, { headers: { "Content-Type": "multipart/form-data" } })
        .then((response) => {
            return response;
        })
        .catch((err) => {
            console.log(err.message);
        });
};

const imagesAPI = {
    sendImage,
};

export default imagesAPI;
