import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { Routes, Route } from "react-router-dom";
import Home from "./views/Home";
import EditImage from "./views/EditImage";
import handleScreenSize from "./settings/screenWidth";
import Loading from "./components/Loading";
import { useSelector } from "react-redux";
import ErrorModal from "./components/ErrorModal";
import TagManager from "react-gtm-module";

const AppStyled = styled.div({
    height: "100%",
    width: "100%",
    backgroundColor: "black",
    color: "white",
});

function App() {
    const loading = useSelector((state) => state.manage.loading);
    const error = useSelector((state) => state.manage.error);

    useEffect(() => {
        TagManager.initialize({ gtmId: "G-BC4BE2V6NF" });
    }, []);

    useEffect(() => {
        handleScreenSize();
    }, []);
    return (
        <AppStyled>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/edit" element={<EditImage />} />
            </Routes>
            {loading ? <Loading /> : null}
            <ErrorModal open={error} />
        </AppStyled>
    );
}

export default App;
