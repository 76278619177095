import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    screenWidth: 0,
    mobileScreen: true,
    loading: false,
    error: false,
};

export const manageSlice = createSlice({
    name: "manage",
    initialState,
    reducers: {
        setScreenWidth: (state, action) => {
            state.screenWidth = action.payload;
        },
        setMobileScreen: (state, action) => {
            state.mobileScreen = action.payload;
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
    },
});

export const { setScreenWidth, setMobileScreen, setLoading, setError } = manageSlice.actions;

export default manageSlice.reducer;
