import React, { useState, useRef, useEffect } from "react";
import styled from "@emotion/styled";
import { useSelector } from "react-redux";
import GoBackButton from "../components/GoBackButton";
import StickerDrawer from "../components/StickerDrawer";
import { Stage, Layer } from "react-konva";
import Sticker from "../components/Sticker";
import CanvasEditedImage from "../components/CanvasEditedImage";
import DeleteSticker from "../components/DeleteSticker";
import EditModeButton from "../components/EditModeButton";
import DownloadShareButtons from "../components/DownloadShareButtons";
import DiscardImageModal from "../components/DiscardImageModal";
import CanvasEditedImageMobile from "../components/CanvasEditedImageMobile";
import StickerMobile from "../components/StickerMobile";

const EditImageStyled = styled.div({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    touchAction: "none",
});

function EditImage() {
    const mobileScreen = useSelector((state) => state.manage.mobileScreen);
    const imageObj = useSelector((state) => state.imageHandling.imageObj);
    const stickers = useSelector((state) => state.imageHandling.selectedStickers);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [showDiscardModal, setShowDiscardModal] = useState(false);

    const [selectedSticker, setSelectedSticker] = useState(null);
    const [imageSelected, setImageSelected] = useState(false);
    const [draggingId, setDraggingID] = useState(null);

    const stageRef = useRef(null);
    const [imgWidth, setImgWidth] = useState(0);
    const [imgHeight, setImgHeight] = useState(0);
    const [x, setX] = useState(0);
    const [y, setY] = useState(0);

    const toggleDrawer = (newOpen) => {
        setOpenDrawer(newOpen);
    };

    useEffect(() => {
        if (imageObj) {
            let w = 0;
            let h = 0;
            const ratio = imageObj.width / imageObj.height;

            const theSameTypes = () => {
                w = window.innerWidth;
                h = window.innerWidth / ratio;
                if (h > window.innerHeight) {
                    h = window.innerHeight - 20;
                    w = h * ratio;
                }
            };

            if (imageObj.width > imageObj.height) {
                if (window.innerWidth > window.innerHeight) {
                    theSameTypes(); //album picture and album screen
                } else {
                    w = window.innerWidth; //album picture and portrait screen
                    h = w / ratio;
                }
            } else {
                if (window.innerWidth > window.innerHeight) {
                    h = window.innerHeight - 20; // portrait picture and album screen
                    w = h * ratio;
                } else {
                    theSameTypes(); // portrait picture and portrait screen
                }
            }
            setImgWidth(w);
            setImgHeight(h);
            const centerX = window.innerWidth / 2 - w / 2;
            setX(centerX);
            const centerY = window.innerHeight / 2 - h / 2;
            setY(centerY);
        }
    }, [imageObj]);

    const selectSticker = (id) => {
        setSelectedSticker(id);
        setImageSelected(false);
    };

    const selectImage = () => {
        setImageSelected(true);
        setSelectedSticker(null);
    };

    const deselect = (e) => {
        const clickedOnEmpty = e.target === e.target.getStage();
        if (clickedOnEmpty) {
            setImageSelected(false);
            setSelectedSticker(null);
        }
    };

    return (
        <EditImageStyled onPointerDown={(e) => e.target.releasePointerCapture(e.pointerId)}>
            <div style={{ width: "100%" }}>
                <Stage width={window.innerWidth} height={window.innerHeight} ref={stageRef} onClick={deselect} onTap={deselect}>
                    <Layer>
                        {imageObj && imageObj.type === "image" && mobileScreen ? (
                            <CanvasEditedImageMobile
                                width={imgWidth}
                                height={imgHeight}
                                imageObj={imageObj}
                                y={y}
                                x={x}
                                draggable={stickers.length > 0 ? false : true}
                            />
                        ) : null}
                        {imageObj && imageObj.type === "image" && !mobileScreen ? (
                            <CanvasEditedImage
                                width={imgWidth}
                                height={imgHeight}
                                imageObj={imageObj}
                                y={y}
                                x={x}
                                imageSelected={stickers.length > 0 ? null : imageSelected}
                                selectImage={selectImage}
                            />
                        ) : null}
                        {stickers.map((stickerObj) => {
                            if (mobileScreen) {
                                return (
                                    <StickerMobile
                                        sticker={stickerObj}
                                        key={stickerObj.id}
                                        onClick={() => selectSticker(stickerObj.id)}
                                        onTap={() => selectSticker(stickerObj.id)}
                                        setDraggingID={setDraggingID}
                                        draggingId={draggingId}
                                        selected={selectedSticker === stickerObj.id ? true : false}
                                    />
                                );
                            } else {
                                return (
                                    <Sticker
                                        sticker={stickerObj}
                                        key={stickerObj.id}
                                        onClick={() => selectSticker(stickerObj.id)}
                                        onTap={() => selectSticker(stickerObj.id)}
                                        setDraggingID={setDraggingID}
                                        draggingId={draggingId}
                                        selected={selectedSticker === stickerObj.id ? true : false}
                                    />
                                );
                            }
                        })}
                    </Layer>
                </Stage>
                <GoBackButton onClick={() => setShowDiscardModal(true)} />
                <EditModeButton onClick={() => toggleDrawer(true)} />
            </div>
            <DownloadShareButtons
                imageObj={imageObj}
                imageSelected={imageSelected}
                selectedSticker={selectedSticker}
                stickers={stickers}
                stageRef={stageRef}
                setImageSelected={setImageSelected}
                setSelectedSticker={setSelectedSticker}
            />
            <DeleteSticker draggingId={draggingId} setDraggingID={setDraggingID} />
            <StickerDrawer open={openDrawer} toggleDrawer={toggleDrawer} mobileScreen={mobileScreen} />
            <DiscardImageModal open={showDiscardModal} setOpen={setShowDiscardModal} />
        </EditImageStyled>
    );
}

export default EditImage;
