import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    imageObj: null,
    selectedStickers: [],
};

export const imageHandlingSlice = createSlice({
    name: "imageHandling",
    initialState,
    reducers: {
        setImage: (state, action) => {
            state.imageObj = action.payload;
        },
        addSelectedSticker: (state, action) => {
            const arr = [...state.selectedStickers];
            arr.push(action.payload);
            state.selectedStickers = arr;
        },
        cleanSelectedStickers: (state, action) => {
            state.selectedStickers = [];
        },
        deleteSticker: (state, action) => {
            const id = action.payload;
            const newStickers = state.selectedStickers.filter((sticker) => sticker.id !== id);
            state.selectedStickers = newStickers;
        },
    },
});

export const { setImage, addSelectedSticker, cleanSelectedStickers, deleteSticker } = imageHandlingSlice.actions;

export default imageHandlingSlice.reducer;
