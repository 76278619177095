import React, { useEffect, useMemo } from "react";
import { Image } from "react-konva";
//import "gifler";

function AnimatedGIFImage({ src, ...otherProps }, ref) {
    const canvas = useMemo(() => {
        const node = document.createElement("canvas");
        return node;
    }, []);

    useEffect(() => {
        // save animation instance to stop it on unmount
        if (src && canvas) {
            let anim;
            window.gifler(src).get((a) => {
                anim = a;
                anim.animateInCanvas(canvas);
                anim.onDrawFrame = (ctx, frame) => {
                    ctx.drawImage(frame.buffer, frame.x, frame.y);
                    ref.current.getLayer().draw();
                };
            });
            return () => anim.stop();
        }
    }, [src, canvas]);
    return <Image image={canvas} ref={ref} {...otherProps} />;
}

export default React.forwardRef(AnimatedGIFImage);
