import React, { useState, useEffect } from "react";
import useImage from "use-image";
import { Image, Transformer } from "react-konva";
import AnimatedGIFImage from "./AnimatedGIFImage";

function Sticker({ sticker, setDraggingID, draggingId, selected, ...props }) {
    const [image] = useImage("../assets/" + sticker.name);
    const [x, setX] = useState(100);
    const [y, setY] = useState(100);
    let showWidth;
    let showHeight;
    let ratio;
    if (sticker.width > sticker.height) {
        showWidth = 150;
        ratio = showWidth / sticker.width;
        showHeight = sticker.height * ratio;
    } else {
        showHeight = 150;
        ratio = showHeight / sticker.height;
        showWidth = sticker.width * ratio;
    }

    const shapeRef = React.useRef();
    const trRef = React.useRef();
    useEffect(() => {
        if (selected) {
            trRef.current.nodes([shapeRef.current]);
            trRef.current.getLayer().batchDraw();
        }
    }, [selected]);

    const savePositions = (e, id) => {
        const newX = e.target.x();
        const newY = e.target.y();
        setX(newX);
        setY(newY);
    };

    const dragEnd = (e, id) => {
        savePositions(e, id);
        setDraggingID(null);
    };
    if (sticker.type !== "gif") {
        return (
            <React.Fragment>
                <Image
                    {...props}
                    image={image}
                    x={x}
                    y={y}
                    width={showWidth}
                    height={showHeight}
                    draggable
                    onDragStart={() => setDraggingID(sticker.id)}
                    //onDragMove={(e) => console.log(e.target.y())}
                    onDragEnd={(e) => dragEnd(e, sticker.id)}
                    ref={shapeRef}
                />
                {selected && (
                    <Transformer
                        ref={trRef}
                        boundBoxFunc={(oldBox, newBox) => {
                            // limit resize
                            if (newBox.width < 5 || newBox.height < 5) {
                                return oldBox;
                            }
                            return newBox;
                        }}
                    />
                )}
            </React.Fragment>
        );
    } else {
        return (
            <React.Fragment>
                <AnimatedGIFImage
                    {...props}
                    x={x}
                    y={y}
                    width={showWidth}
                    height={showHeight}
                    draggable
                    onDragStart={() => setDraggingID(sticker.id)}
                    //onDragMove={(e) => console.log(e.target.y())}
                    onDragEnd={(e) => dragEnd(e, sticker.id)}
                    src={"../assets/" + sticker.name}
                    ref={shapeRef}
                />
                {selected && (
                    <Transformer
                        ref={trRef}
                        boundBoxFunc={(oldBox, newBox) => {
                            // limit resize
                            if (newBox.width < 5 || newBox.height < 5) {
                                return oldBox;
                            }
                            return newBox;
                        }}
                    />
                )}
            </React.Fragment>
        );
    }
}

export default Sticker;
