import React from "react";
import styled from "@emotion/styled";
import Button from "@mui/material/Button";
import { useDispatch } from "react-redux";
import { setImage } from "../store/imageHandling";
import { useNavigate } from "react-router-dom";
import reactImageSize from "react-image-size";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const UploadPhotoStyled = styled.div({
    marginTop: "70px",
    marginBottom: "70px",
});

const Input = styled("input")({
    display: "none",
});

const ButtonStyled = styled(Button)({
    backgroundColor: "#F48126",
    textTransform: "none",
});

function UploadPhoto({ mobileScreen }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleUpload = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.addEventListener("load", async () => {
            const src = reader.result;
            const { width, height } = await reactImageSize(src);
            const obj = { src, width, height, type: "image" };
            dispatch(setImage(obj));
            navigate("/edit");
        });
        if (file) {
            reader.readAsDataURL(file);
        }
    };
    return (
        <UploadPhotoStyled>
            <label htmlFor="contained-button-file">
                <Input accept="image/*" id="contained-button-file" type="file" onChange={handleUpload} />
                <ButtonStyled
                    variant="contained"
                    component="span"
                    sx={{
                        width: mobileScreen ? "252px" : "252px",
                        height: mobileScreen ? "68px" : "68px",
                        borderRadius: mobileScreen ? "34px" : "34px",
                        fontSize: "16px",
                        justifyContent: "space-between",
                        "&.MuiButtonBase-root.MuiButton-root": {
                            paddingLeft: "40px",
                            paddingRight: "40px",
                        },
                        "&.MuiButtonBase-root.MuiButton-root:hover": {
                            backgroundColor: "#F48126",
                            opacity: "0.5",
                        },
                    }}
                    endIcon={<ArrowForwardIcon style={{ fontSize: "23px" }} />}
                >
                    Upload photo
                </ButtonStyled>
            </label>
        </UploadPhotoStyled>
    );
}

export default UploadPhoto;
