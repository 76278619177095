import React, { useState } from "react";
import styled from "@emotion/styled";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { deleteSticker } from "../store/imageHandling";
import { useDispatch } from "react-redux";

const DeleteStickerStyled = styled.div({
    position: "fixed",
    bottom: "60px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "50px",
    touchAction: "none",
});
const Circle = styled.div({
    width: "50px",
    height: "50px",
    borderRadius: "25px",
    border: "2px solid white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    touchAction: "none",
});

function DeleteSticker({ draggingId, setDraggingID }) {
    const dispatch = useDispatch();
    const [hover, setHover] = useState(false);

    const checkDelete = () => {
        dispatch(deleteSticker(draggingId));
        setHover(false);
        setDraggingID(null);
    };

    if (draggingId) {
        return (
            <DeleteStickerStyled>
                {hover ? (
                    <Circle>
                        <DeleteOutlineIcon
                            style={{ fontSize: "40px" }}
                            onPointerEnter={() => setHover(true)}
                            onPointerLeave={() => setHover(false)}
                            onPointerUp={checkDelete}
                        />
                    </Circle>
                ) : (
                    <DeleteOutlineIcon
                        style={{ fontSize: "40px" }}
                        onPointerEnter={() => setHover(true)}
                        onPointerLeave={() => setHover(false)}
                        onPointerUp={checkDelete}
                    />
                )}
            </DeleteStickerStyled>
        );
    }
    return null;
}

export default DeleteSticker;
