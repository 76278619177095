import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import IconButton from "@mui/material/IconButton";
import DownloadIcon from "@mui/icons-material/Download";
import ShareIcon from "@mui/icons-material/Share";
import ShareModal from "./ShareModal";
import { useDispatch } from "react-redux";
import { setLoading, setError } from "../store/manage";
import imagesAPI from "../api/images";

const DownloadButton = styled(IconButton)({
    position: "fixed",
    bottom: "30px",
    left: "30px",
    backgroundColor: "#e79704",
});

const ShareButton = styled(IconButton)({
    position: "fixed",
    bottom: "30px",
    right: "30px",
    backgroundColor: "#e79704",
});

function downloadURI(uri, name) {
    const link = document.createElement("a");
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

const shareTwitter = (url) => {
    const link = document.createElement("a");
    link.classList.add("twitter-share-button");
    link.href = "https://twitter.com/intent/tweet?text=STE-Social " + url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

const shareFacebook = (url) => {
    const link = document.createElement("a");
    link.classList.add("twitter-share-button");
    link.href = "http://www.facebook.com/share.php?u=" + url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

function DownloadShareButtons({ imageObj, imageSelected, selectedSticker, stickers, stageRef, setImageSelected, setSelectedSticker }) {
    const dispatch = useDispatch();
    const [showShareModal, setShowShareModal] = useState(false);
    const [save, setSave] = useState(null);

    const send = (image) => {
        const formData = new FormData();
        formData.append("image", image);
        imagesAPI
            .sendImage(formData)
            .then((response) => {
                dispatch(setLoading(false));
                setSave(null);
                if (response.status === 200) {
                    if (save === "Twitter") {
                        shareTwitter(response.data.img);
                    }
                    if (save === "Facebook") {
                        shareFacebook(response.data.img);
                    }
                } else {
                    dispatch(setError(true));
                }
            })
            .catch((err) => {
                dispatch(setLoading(false));
                setSave(null);
                dispatch(setError(true));
            });
    };

    useEffect(() => {
        if (save && !imageSelected && !selectedSticker) {
            const animated = stickers.some((sticker) => sticker.type === "gif");
            let obj;
            if (!animated) {
                const src = stageRef.current.toDataURL({ mimeType: "image/png" });
                obj = {
                    src,
                    width: window.innerWidth,
                    height: window.innerHeight,
                    type: "image",
                };
                if (save === "download") {
                    downloadURI(obj.src, "image.png");
                    setSave(null);
                }
                if (save === "Twitter" || save === "Facebook") {
                    dispatch(setLoading(true));
                    const canvas = document.getElementsByTagName("canvas")[0];
                    canvas.toBlob((blob) => {
                        send(blob);
                    });
                }
            } else {
                dispatch(setLoading(true));
                const canvas = document.getElementsByTagName("canvas")[0];
                startRecording();

                function startRecording() {
                    const chunks = [];
                    const stream = canvas.captureStream();
                    const rec = new MediaRecorder(stream);
                    rec.ondataavailable = (e) => chunks.push(e.data);
                    rec.onstop = () => {
                        const blob = new Blob(chunks, { type: "video/mp4" });
                        const src = URL.createObjectURL(blob);
                        obj = {
                            src,
                            width: window.innerWidth,
                            height: window.innerHeight,
                            type: "video",
                        };
                        if (save === "download") {
                            downloadURI(obj.src, "myvid.mp4");
                            dispatch(setLoading(false));
                            setSave(null);
                        }
                        if (save === "Twitter" || save === "Facebook") {
                            send(blob);
                        }
                    };
                    rec.start();
                    setTimeout(() => rec.stop(), 3000); // stop recording in 3s
                }
            }
        }
    }, [save, imageSelected, selectedSticker]);

    const handleSave = (mode) => {
        setImageSelected(false);
        setSelectedSticker(null);
        setShowShareModal(false);
        setSave(mode);
    };

    return (
        <React.Fragment>
            <DownloadButton onClick={() => handleSave("download")}>
                <DownloadIcon style={{ color: "white", width: "35px", height: "35px" }} />
            </DownloadButton>
            <ShareButton onClick={() => setShowShareModal(true)}>
                <ShareIcon style={{ color: "white", width: "35px", height: "35px" }} />
            </ShareButton>
            <ShareModal open={showShareModal} setOpen={setShowShareModal} share={(social) => handleSave(social)} />
        </React.Fragment>
    );
}

export default DownloadShareButtons;
