import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import styled from "@emotion/styled";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import IconButton from "@mui/material/IconButton";

const style = {
    maxWidth: "700px",
    color: "white",
    bgcolor: "#363636",
    padding: "20px 20px",
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    height: "510px",
};

const Container = styled.div({
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
});

const IconContainer = styled.div({
    marginTop: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
});

const Title = styled.p({
    fontWeight: 500,
});

export default function InfoModal({ open, setOpen, share }) {
    const handleClose = () => setOpen(false);

    return (
        <Modal
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Fade in={open}>
                <Container>
                    <Box sx={style}>
                        <Title>Step 1: Upload</Title>
                        <p>From your phone or computer, add a photo into the app. Resize the photo if needed.</p>
                        <Title>Step 2: Add flair</Title>
                        <p>Click the smiley face button in the top right corner to access the sticker library.</p>
                        <p>
                            Select the sticker(s) you want to use. If desired, drag a sticker to the trash to delete it. Resize and rotate the sticker
                            until you have the design you like.
                        </p>
                        <Title>Step 3: Save and/or share!</Title>
                        <p>Save your creation to your phone or computer by using the button on the bottom left.</p>
                        <p>Share it to Twitter or Facebook by using the button on the bottom right.</p>
                        <IconContainer>
                            <IconButton style={{ width: "50px" }} onClick={() => setOpen(false)}>
                                <CheckCircleOutlineIcon sx={{ color: "white", width: "40px", height: "40px" }} />
                            </IconButton>
                        </IconContainer>
                    </Box>
                </Container>
            </Fade>
        </Modal>
    );
}
