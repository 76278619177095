import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import styled from "@emotion/styled";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import { useDispatch } from "react-redux";
import { setImage } from "../store/imageHandling";
import { cleanSelectedStickers } from "../store/imageHandling";
import { useNavigate } from "react-router-dom";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 240,
    color: "white",
    bgcolor: "rgba(0, 0, 0, 0.7)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "20px",
    borderRadius: "10px",
};

const Header = styled.div({
    fontSize: "18px",
});

const Question = styled.div({
    marginTop: "20px",
    marginBottom: "40px",
    marginLeft: "20px",
    marginRight: "20px",
    textAlign: "center",
    fontSize: "14px",
    color: "#C7C6C6",
});

const Discard = styled(Button)({
    color: "#FF2B2B",
    height: "50px",
    textTransform: "none",
    fontSize: "16px",
    width: "240px",
});

const Cancel = styled(Button)({
    color: "white",
    height: "50px",
    textTransform: "none",
    fontSize: "16px",
    width: "240px",
});

const MyDivider = styled(Divider)({
    width: "100%",
    backgroundColor: "#363636",
});

function DiscardImageModal({ open, setOpen }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const handleClose = () => setOpen(false);

    const goBack = () => {
        dispatch(setImage(null));
        dispatch(cleanSelectedStickers());
        navigate("/");
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <Box sx={style}>
                    <Header>Discard Media?</Header>
                    <Question>If you go back now, you will lose any changes you’ve made.</Question>
                    <MyDivider />
                    <Discard variant="text" onClick={goBack}>
                        Discard
                    </Discard>
                    <MyDivider />
                    <Cancel variant="text" onClick={handleClose}>
                        Cancel
                    </Cancel>
                </Box>
            </Fade>
        </Modal>
    );
}

export default DiscardImageModal;
