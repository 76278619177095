import React from "react";
import styled from "@emotion/styled";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const CloseDrawerButtonStyled = styled(IconButton)({
    position: "absolute",
    top: "8px",
    right: "8px",
});

function CloseDrawerButton({ ...props }) {
    return (
        <CloseDrawerButtonStyled {...props}>
            <CloseIcon style={{ color: "white" }} />
        </CloseDrawerButtonStyled>
    );
}

export default CloseDrawerButton;
