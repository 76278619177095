import React from "react";
import styled from "@emotion/styled";
import IconButton from "@mui/material/IconButton";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";

const EditModeButtonStyled = styled(IconButton)({
    position: "fixed",
    top: "20px",
    right: "30px",
    backgroundColor: "rgba(0, 0, 0, 0.7)",
});

function EditModeButton({ ...props }) {
    return (
        <EditModeButtonStyled {...props}>
            <SentimentSatisfiedAltIcon style={{ color: "white", width: "42px", height: "42px" }} />
        </EditModeButtonStyled>
    );
}

export default EditModeButton;
