import React from "react";
import styled from "@emotion/styled";

const SafetyTakesEveryoneStyled = styled.div(({ mobileScreen }) => {
    return {
        marginTop: mobileScreen ? "40px" : "40px",
    };
});

function SafetyTakesEveryone({ mobileScreen }) {
    return (
        <SafetyTakesEveryoneStyled mobileScreen={mobileScreen}>
            <svg width="300" height="125" viewBox="0 0 300 125" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M35.143 37.013L30.718 36.14C26.528 35.309 25.759 34.11 25.759 32.051C25.759 29.239 28.046 27.8149 32.552 27.8149C36.99 27.8149 39.365 29.568 39.619 33.025C39.622 33.0645 39.6396 33.1015 39.6685 33.1287C39.6974 33.1559 39.7353 33.1714 39.775 33.172H41.156C41.1773 33.1718 41.1983 33.1673 41.2179 33.1589C41.2375 33.1505 41.2552 33.1383 41.27 33.123C41.2997 33.0925 41.3162 33.0515 41.316 33.009C41.169 28.617 38.006 26.297 32.16 26.297C27.054 26.297 23.881 28.49 23.881 32.021C23.881 35.253 25.614 36.765 30.505 37.801L34.505 38.645C38.796 39.531 40.037 40.489 40.037 42.913C40.037 45.852 37.473 47.54 33.003 47.54C27.591 47.54 24.945 45.504 24.916 41.317C24.9152 41.2751 24.898 41.2353 24.868 41.2061C24.8381 41.1769 24.7978 41.1607 24.756 41.1609H23.375C23.3336 41.1609 23.2939 41.1774 23.2647 41.2067C23.2354 41.2359 23.219 41.2756 23.219 41.317C23.219 46.455 26.549 49.058 33.119 49.058C41.053 49.058 41.91 44.699 41.91 42.822C41.917 39.102 39.209 37.807 35.143 37.013Z"
                    fill="white"
                />
                <path
                    d="M56.314 26.8699C56.3011 26.8428 56.2809 26.8199 56.2556 26.8038C56.2303 26.7877 56.201 26.7791 56.171 26.7789H54.607C54.577 26.7789 54.5476 26.7875 54.5222 26.8036C54.4969 26.8198 54.4767 26.8427 54.464 26.8699L44.595 48.355C44.5845 48.3789 44.5799 48.405 44.5817 48.4312C44.5834 48.4573 44.5914 48.4826 44.605 48.5049C44.6196 48.5271 44.6395 48.5454 44.6629 48.5579C44.6863 48.5705 44.7124 48.577 44.739 48.577H46.302C46.332 48.5769 46.3614 48.5684 46.3867 48.5522C46.412 48.5361 46.4322 48.5131 46.445 48.486L49.563 41.657H61.214L64.332 48.486C64.3447 48.5131 64.3649 48.5361 64.3902 48.5522C64.4156 48.5684 64.445 48.5769 64.475 48.577H66.039C66.0656 48.5774 66.0919 48.571 66.1154 48.5584C66.1388 48.5458 66.1586 48.5274 66.173 48.5049C66.1873 48.4829 66.1958 48.4575 66.1975 48.4313C66.1993 48.405 66.1943 48.3788 66.183 48.355L56.314 26.8699ZM60.53 40.137H50.248L55.389 28.877L60.53 40.137Z"
                    fill="white"
                />
                <path
                    d="M85.948 26.7789H70.361C70.319 26.7787 70.2786 26.7951 70.2486 26.8245C70.2186 26.8539 70.2015 26.894 70.201 26.936V48.421C70.2018 48.4628 70.219 48.5026 70.249 48.5318C70.2789 48.561 70.3192 48.5772 70.361 48.577H71.742C71.7834 48.577 71.823 48.5605 71.8523 48.5313C71.8816 48.502 71.898 48.4623 71.898 48.421V38.041H85.161C85.2024 38.041 85.242 38.0245 85.2713 37.9952C85.3006 37.966 85.317 37.9263 85.317 37.885V36.679C85.317 36.6376 85.3006 36.5979 85.2713 36.5687C85.242 36.5394 85.2024 36.523 85.161 36.523H71.902V28.293H85.948C85.9688 28.2928 85.9893 28.2886 86.0085 28.2805C86.0276 28.2723 86.0449 28.2604 86.0594 28.2455C86.0739 28.2307 86.0854 28.2131 86.093 28.1937C86.1007 28.1744 86.1044 28.1537 86.104 28.1329V26.933C86.1035 26.892 86.0868 26.8527 86.0576 26.8239C86.0284 26.7951 85.989 26.7789 85.948 26.7789V26.7789Z"
                    fill="white"
                />
                <path
                    d="M107.788 47.058H92.178V38.041H106.978C107.019 38.041 107.059 38.0245 107.088 37.9952C107.118 37.966 107.134 37.9263 107.134 37.8849V36.679C107.134 36.6376 107.118 36.5979 107.088 36.5687C107.059 36.5394 107.019 36.5229 106.978 36.5229H92.178V28.293H107.609C107.63 28.2928 107.65 28.2886 107.669 28.2805C107.689 28.2723 107.706 28.2604 107.72 28.2455C107.735 28.2307 107.746 28.2131 107.754 28.1937C107.762 28.1744 107.765 28.1537 107.765 28.1329V26.933C107.765 26.9124 107.761 26.892 107.753 26.8729C107.746 26.8539 107.734 26.8366 107.72 26.822C107.705 26.8074 107.688 26.7958 107.669 26.7879C107.65 26.78 107.63 26.7759 107.609 26.7759H90.636C90.5947 26.7767 90.5553 26.7936 90.5262 26.8229C90.4971 26.8522 90.4805 26.8917 90.48 26.933V48.418C90.4805 48.4592 90.4971 48.4986 90.5262 48.5277C90.5554 48.5569 90.5948 48.5735 90.636 48.574H107.787C107.828 48.574 107.868 48.5575 107.897 48.5283C107.927 48.499 107.943 48.4593 107.943 48.418V47.212C107.942 47.1712 107.926 47.1323 107.897 47.1036C107.868 47.0748 107.829 47.0585 107.788 47.058V47.058Z"
                    fill="white"
                />
                <path
                    d="M130.097 26.7789H111.378C111.337 26.7797 111.297 26.7966 111.268 26.8259C111.239 26.8552 111.223 26.8947 111.222 26.936V28.1359C111.222 28.1778 111.238 28.218 111.267 28.248C111.296 28.2779 111.336 28.2952 111.378 28.296H119.888V48.4189C119.888 48.4603 119.904 48.5 119.934 48.5292C119.963 48.5585 120.003 48.575 120.044 48.575H121.429C121.47 48.5744 121.51 48.5578 121.539 48.5287C121.568 48.4995 121.584 48.4602 121.585 48.4189V28.2969H130.095C130.137 28.2961 130.177 28.2789 130.206 28.249C130.235 28.219 130.251 28.1788 130.251 28.137V26.937C130.251 26.8958 130.235 26.8564 130.206 26.8269C130.177 26.7974 130.138 26.7802 130.097 26.7789V26.7789Z"
                    fill="white"
                />
                <path
                    d="M154.544 26.779H152.798C152.775 26.7794 152.752 26.7849 152.731 26.7951C152.71 26.8053 152.692 26.8199 152.677 26.838L143.893 37.848L135.293 26.838C135.278 26.8196 135.259 26.8048 135.238 26.7946C135.216 26.7844 135.193 26.779 135.169 26.779H133.244C133.215 26.7791 133.186 26.7875 133.161 26.8031C133.137 26.8186 133.117 26.8407 133.104 26.867C133.09 26.8932 133.085 26.9229 133.087 26.9524C133.09 26.9818 133.102 27.0098 133.12 27.033L143.048 39.447V48.42C143.048 48.4614 143.064 48.501 143.094 48.5303C143.123 48.5596 143.163 48.576 143.204 48.576H144.585C144.627 48.5762 144.667 48.56 144.697 48.5308C144.727 48.5016 144.744 48.4618 144.745 48.42V39.447L154.67 27.033C154.689 27.0099 154.7 26.982 154.704 26.9525C154.707 26.923 154.702 26.8932 154.689 26.8665C154.676 26.8399 154.655 26.8175 154.63 26.802C154.605 26.7865 154.576 26.7785 154.546 26.779H154.544Z"
                    fill="white"
                />
                <path
                    d="M185.059 28.1409V26.941C185.059 26.9204 185.055 26.9 185.047 26.8809C185.04 26.8619 185.028 26.8446 185.014 26.83C184.999 26.8154 184.982 26.8038 184.963 26.7959C184.944 26.788 184.924 26.7839 184.903 26.7839H166.187C166.146 26.7847 166.106 26.8016 166.077 26.8309C166.048 26.8602 166.032 26.8997 166.031 26.941V28.1409C166.031 28.1828 166.047 28.223 166.076 28.253C166.105 28.2829 166.145 28.3002 166.187 28.301H174.697V48.424C174.697 48.4653 174.713 48.505 174.743 48.5342C174.772 48.5635 174.812 48.58 174.853 48.58H176.238C176.279 48.5794 176.319 48.5628 176.348 48.5337C176.377 48.5045 176.393 48.4652 176.394 48.424V28.2969H184.901C184.922 28.2971 184.942 28.2932 184.961 28.2854C184.98 28.2776 184.998 28.2661 185.012 28.2516C185.027 28.2372 185.039 28.22 185.047 28.201C185.055 28.182 185.059 28.1615 185.059 28.1409Z"
                    fill="white"
                />
                <path
                    d="M195.13 26.8699C195.117 26.8428 195.097 26.8199 195.072 26.8038C195.046 26.7877 195.017 26.7791 194.987 26.7789H193.423C193.393 26.7791 193.364 26.7877 193.338 26.8038C193.313 26.8199 193.293 26.8428 193.28 26.8699L183.412 48.355C183.401 48.3788 183.396 48.405 183.397 48.4313C183.399 48.4575 183.408 48.4829 183.422 48.5049C183.436 48.5274 183.456 48.5458 183.48 48.5584C183.503 48.571 183.529 48.5774 183.556 48.577H185.12C185.15 48.5769 185.179 48.5684 185.205 48.5522C185.23 48.5361 185.25 48.5131 185.263 48.486L188.381 41.657H200.032L203.15 48.486C203.163 48.5131 203.183 48.5361 203.208 48.5522C203.234 48.5684 203.263 48.5769 203.293 48.577H204.857C204.884 48.5774 204.91 48.571 204.933 48.5584C204.957 48.5458 204.977 48.5274 204.991 48.5049C205.005 48.4829 205.014 48.4575 205.016 48.4313C205.017 48.405 205.012 48.3788 205.001 48.355L195.13 26.8699ZM199.346 40.137H189.063L194.204 28.877L199.346 40.137Z"
                    fill="white"
                />
                <path
                    d="M217.322 35.8299L228.322 27.0589C228.348 27.0389 228.368 27.011 228.377 26.9792C228.386 26.9475 228.386 26.9135 228.374 26.8824C228.363 26.8512 228.342 26.8244 228.315 26.8058C228.288 26.7872 228.255 26.7777 228.222 26.7789H226.022C225.985 26.7789 225.95 26.7917 225.922 26.8149L210.713 38.9479V26.9359C210.713 26.8946 210.696 26.8551 210.667 26.8258C210.638 26.7965 210.598 26.7797 210.557 26.7789H209.172C209.13 26.7786 209.09 26.795 209.06 26.8244C209.03 26.8539 209.013 26.894 209.012 26.9359V48.4209C209.013 48.4628 209.03 48.5026 209.06 48.5318C209.09 48.561 209.13 48.5772 209.172 48.5769H210.557C210.598 48.5762 210.637 48.5595 210.666 48.5304C210.696 48.5013 210.712 48.4621 210.713 48.4209V41.0929L215.985 36.8929L226.959 48.5249C226.989 48.5554 227.03 48.573 227.073 48.5739H228.999C229.03 48.5739 229.06 48.5651 229.086 48.5484C229.112 48.5318 229.133 48.508 229.146 48.48C229.158 48.4517 229.161 48.4205 229.155 48.3904C229.149 48.3603 229.135 48.3327 229.113 48.3109L217.322 35.8299Z"
                    fill="white"
                />
                <path
                    d="M250.439 47.058H234.829V38.041H249.629C249.671 38.0412 249.711 38.025 249.741 37.9958C249.771 37.9666 249.788 37.9268 249.789 37.8849V36.679C249.788 36.6371 249.771 36.5973 249.741 36.5681C249.711 36.5389 249.671 36.5227 249.629 36.5229H234.829V28.293H250.26C250.302 28.2922 250.342 28.2749 250.371 28.245C250.4 28.215 250.416 28.1748 250.416 28.1329V26.933C250.416 26.8917 250.399 26.8522 250.37 26.8229C250.341 26.7936 250.301 26.7767 250.26 26.7759H233.283C233.262 26.7759 233.242 26.78 233.223 26.7879C233.204 26.7958 233.187 26.8074 233.172 26.822C233.158 26.8366 233.146 26.8539 233.139 26.8729C233.131 26.892 233.127 26.9124 233.127 26.933V48.418C233.127 48.4593 233.143 48.499 233.173 48.5283C233.202 48.5575 233.242 48.574 233.283 48.574H250.434C250.475 48.5735 250.515 48.5569 250.544 48.5277C250.573 48.4986 250.59 48.4592 250.59 48.418V47.212C250.589 47.172 250.573 47.1339 250.545 47.1054C250.517 47.0769 250.479 47.06 250.439 47.058V47.058Z"
                    fill="white"
                />
                <path
                    d="M265.43 37.013L261.009 36.14C256.816 35.309 256.05 34.11 256.05 32.051C256.05 29.239 258.334 27.8149 262.843 27.8149C267.278 27.8149 269.656 29.568 269.907 33.025C269.91 33.0645 269.928 33.1015 269.957 33.1287C269.985 33.1559 270.023 33.1714 270.063 33.172H271.448C271.491 33.1709 271.532 33.1534 271.562 33.123C271.577 33.1081 271.588 33.0902 271.595 33.0705C271.603 33.0508 271.606 33.0299 271.604 33.009C271.461 28.617 268.294 26.297 262.452 26.297C257.343 26.297 254.17 28.49 254.17 32.021C254.17 35.253 255.903 36.765 260.794 37.801L264.794 38.645C269.088 39.531 270.327 40.489 270.327 42.913C270.327 45.852 267.763 47.54 263.292 47.54C257.883 47.54 255.238 45.504 255.205 41.317C255.205 41.2756 255.189 41.2359 255.159 41.2067C255.13 41.1774 255.09 41.1609 255.049 41.1609H253.664C253.623 41.1617 253.584 41.1784 253.555 41.2075C253.525 41.2366 253.509 41.2758 253.508 41.317C253.508 46.455 256.841 49.058 263.413 49.058C271.35 49.058 272.207 44.699 272.207 42.822C272.206 39.102 269.496 37.807 265.43 37.013Z"
                    fill="white"
                />
                <path d="M23.162 56.703H46.68L48.703 65.859H35.057V72.076H47.849V80.893H35.057V87.901H49.277V97.057H23.162V56.703Z" fill="#F48120" />
                <path d="M69.953 56.703H82.504L73.521 97.056H58.174L49.25 56.703H62.1L65.968 87.903H66.085L69.953 56.703Z" fill="#F48120" />
                <path d="M83.194 56.703H108.774V65.859H95.093V72.076H107.881V80.893H95.093V87.901H109.312V97.057H83.194V56.703Z" fill="#F48120" />
                <path
                    d="M111.124 56.703H128.969C137.18 56.703 140.689 60.545 140.689 67.273C140.689 73.714 137.773 76.428 133.609 76.992V77.106C134.742 77.1441 135.849 77.4573 136.833 78.0187C137.818 78.58 138.652 79.3726 139.262 80.328C140.334 82.136 140.393 84.847 140.393 87.222V90.555C140.312 92.1558 140.492 93.7592 140.927 95.302C141.188 95.8349 141.632 96.2559 142.178 96.488V97.055H129.568C128.793 96.149 128.675 93.21 128.675 91.233C128.675 89.256 128.496 87.222 128.496 85.244C128.496 81.794 127.307 81.175 125.225 81.175H123.025V97.056H111.124V56.703ZM123.024 73.714H124.751C126.712 73.714 128.794 72.867 128.794 68.967C128.794 65.634 127.07 64.842 124.394 64.842H123.024V73.714Z"
                    fill="#F48120"
                />
                <path
                    d="M159.016 56.703H171.925L161.215 81.175V97.056H149.315V81.175L138.605 56.703H151.81L155.202 70.775H155.323L159.016 56.703Z"
                    fill="#F48120"
                />
                <path
                    d="M204.602 76.838C204.602 88.252 202.888 98.098 186.923 98.098C170.958 98.098 169.244 88.252 169.244 76.838C169.244 65.499 170.958 55.653 186.923 55.653C202.888 55.653 204.602 65.499 204.602 76.838ZM186.923 88.701C189.533 88.701 190.129 86.238 190.129 76.838C190.129 67.438 189.529 64.978 186.923 64.978C184.317 64.978 183.714 67.441 183.714 76.838C183.714 86.235 184.31 88.701 186.92 88.701H186.923Z"
                    fill="white"
                />
                <path
                    d="M226.747 70.353C226.747 67.293 225.776 66.397 223.912 66.397C221.97 66.397 221.003 68.039 221.003 70.353V97.053H206.531V56.699H220.554V61.322H220.704C222.868 57.37 226.523 55.653 231.371 55.653C236.519 55.653 241.221 58.488 241.221 65.874V97.053H226.747V70.353Z"
                    fill="white"
                />
                <path
                    d="M257.398 84.524C257.398 86.688 257.545 89.597 260.529 89.597C263.364 89.597 263.663 86.984 263.663 82.882H276.794C276.794 88.401 275.302 92.207 272.467 94.595C269.632 96.983 265.527 98.101 260.604 98.101C244.639 98.101 242.925 88.251 242.925 76.838C242.925 65.499 244.639 55.653 260.604 55.653C277.091 55.653 277.24 65.946 277.24 78.553V79.224H257.398V84.524ZM263.664 69.23C263.664 65.274 262.764 64.157 260.455 64.157C258.366 64.157 257.399 65.274 257.399 69.23V71.615H263.665L263.664 69.23Z"
                    fill="white"
                />
                <path d="M299.403 124.131H0.997009V0.265991H299.397L299.403 124.131ZM3.35201 121.266H297.052V3.13403H3.35201V121.266Z" fill="white" />
            </svg>
        </SafetyTakesEveryoneStyled>
    );
}

export default SafetyTakesEveryone;
