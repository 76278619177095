import React from "react";
import styled from "@emotion/styled";
import IconButton from "@mui/material/IconButton";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const GoBackButtonStyled = styled(IconButton)({
    position: "fixed",
    top: "20px",
    left: "45px",
    backgroundColor: "rgba(0, 0, 0, 0.7)",
});

function GoBackButton({ ...props }) {
    return (
        <GoBackButtonStyled {...props}>
            <ArrowBackIosIcon style={{ color: "white", position: "relative", left: "5px", width: "37px", height: "37px" }} />
        </GoBackButtonStyled>
    );
}

export default GoBackButton;
