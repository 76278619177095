import React, { useState, useEffect } from "react";
import useImage from "use-image";
import { Image } from "react-konva";
import AnimatedGIFImage from "./AnimatedGIFImage";

function StickerMobile({ sticker, setDraggingID, draggingId, selected, ...props }) {
    const [image] = useImage("../assets/" + sticker.name);
    const [x, setX] = useState(100);
    const [y, setY] = useState(100);
    let showWidth;
    let showHeight;
    let ratio;
    if (sticker.width > sticker.height) {
        showWidth = 150;
        ratio = showWidth / sticker.width;
        showHeight = sticker.height * ratio;
    } else {
        showHeight = 150;
        ratio = showHeight / sticker.height;
        showWidth = sticker.width * ratio;
    }

    const [canDrag, setCanDrag] = useState(true);
    useEffect(() => {
        setCanDrag(true);
    }, [draggingId]);

    const shapeRef = React.useRef();
    function rotateAroundPoint(shape, angleDegrees, point) {
        let angleRadians = (angleDegrees * Math.PI) / 180; // sin + cos require radians
        const x = point.x + (shape.x() - point.x) * Math.cos(angleRadians) - (shape.y() - point.y) * Math.sin(angleRadians);
        const y = point.y + (shape.x() - point.x) * Math.sin(angleRadians) + (shape.y() - point.y) * Math.cos(angleRadians);
        shape.rotation(shape.rotation() + angleDegrees); // rotate the shape in place
        shape.x(x); // move the rotated shape in relation to the rotation point.
        shape.y(y);
    }

    const pinchEnd = React.useRef(false);
    useEffect(() => {
        if (shapeRef.current) {
            const hammertime = new window.Hammer(shapeRef.current, { domEvents: true });
            hammertime.get("rotate").set({ enable: true });
            hammertime.get("pan").set({ direction: window.Hammer.DIRECTION_ALL });
            hammertime.get("pinch").set({ enable: true });

            let oldRotation = 0;
            let startScale = 0;
            let baseScale = 1;

            let x = shapeRef.current.x();
            let y = shapeRef.current.y();

            shapeRef.current.on("pan", function (ev) {
                let localCanDrag = false;
                setCanDrag((currentCanDrag) => {
                    localCanDrag = currentCanDrag;
                    return currentCanDrag;
                });
                if (localCanDrag) {
                    x = shapeRef.current.x();
                    y = shapeRef.current.y();
                }
            });

            shapeRef.current.on("rotatestart pinchstart", function (ev) {
                let localCanDrag = false;
                setCanDrag((currentCanDrag) => {
                    localCanDrag = currentCanDrag;
                    return currentCanDrag;
                });
                if (localCanDrag) {
                    oldRotation = ev.evt.gesture.rotation;
                    startScale = shapeRef.current.scaleX();
                    x = shapeRef.current.x();
                    y = shapeRef.current.y();
                    shapeRef.current.draggable(false);
                    pinchEnd.current = false;
                }
            });

            shapeRef.current.on("rotate", function (ev) {
                let localCanDrag = false;
                setCanDrag((currentCanDrag) => {
                    localCanDrag = currentCanDrag;
                    return currentCanDrag;
                });
                if (localCanDrag) {
                    if (ev.evt.gesture.rotation !== 0) {
                        let delta = oldRotation - ev.evt.gesture.rotation;
                        if (Math.abs(delta) > 181 || Math.abs(delta) < 179) {
                            oldRotation = ev.evt.gesture.rotation;
                            const point = {
                                x: ev.evt.gesture.center.x,
                                y: ev.evt.gesture.center.y,
                            };
                            rotateAroundPoint(shapeRef.current, -delta, point);
                            x = shapeRef.current.x();
                            y = shapeRef.current.y();
                        }
                    }
                }
            });

            shapeRef.current.on("pinch", function (ev) {
                let localCanDrag = false;
                setCanDrag((currentCanDrag) => {
                    localCanDrag = currentCanDrag;
                    return currentCanDrag;
                });
                if (localCanDrag && !pinchEnd.current) {
                    const newScale = baseScale * ev.evt.gesture.scale;
                    shapeRef.current.scaleX(newScale);
                    shapeRef.current.scaleY(newScale);
                    const point2 = {
                        x: ev.evt.gesture.center.x,
                        y: ev.evt.gesture.center.y,
                    };
                    const pointToX = x - point2.x;
                    const pointToY = y - point2.y;
                    let newX = point2.x + (pointToX * newScale) / startScale;
                    let newY = point2.y + (pointToY * newScale) / startScale;
                    shapeRef.current.x(newX);
                    shapeRef.current.y(newY);
                    startScale = newScale;
                    x = shapeRef.current.x();
                    y = shapeRef.current.y();
                }
            });

            shapeRef.current.on("pinchend pinchcancel", function (ev) {
                let localCanDrag = false;
                setCanDrag((currentCanDrag) => {
                    localCanDrag = currentCanDrag;
                    return currentCanDrag;
                });
                if (localCanDrag) {
                    baseScale = shapeRef.current.scaleX();
                    pinchEnd.current = true;
                }
            });

            shapeRef.current.on("rotateend rotatecancel pinchend pinchcancel", function (ev) {
                let localCanDrag = false;
                setCanDrag((currentCanDrag) => {
                    localCanDrag = currentCanDrag;
                    return currentCanDrag;
                });
                if (localCanDrag) {
                    shapeRef.current.draggable(true);
                }
            });
        }
    }, [shapeRef.current]);

    const savePositions = (e, id) => {
        const newX = e.target.x();
        const newY = e.target.y();
        setX(newX);
        setY(newY);
    };

    const dragEnd = (e, id) => {
        savePositions(e, id);
        setDraggingID(null);
    };
    if (sticker.type !== "gif") {
        return (
            <React.Fragment>
                <Image
                    {...props}
                    image={image}
                    x={x}
                    y={y}
                    width={showWidth}
                    height={showHeight}
                    draggable={canDrag}
                    onDragStart={() => setDraggingID(sticker.id)}
                    onDragEnd={(e) => dragEnd(e, sticker.id)}
                    ref={shapeRef}
                />
            </React.Fragment>
        );
    } else {
        return (
            <React.Fragment>
                <AnimatedGIFImage
                    {...props}
                    x={x}
                    y={y}
                    width={showWidth}
                    height={showHeight}
                    draggable={canDrag}
                    onDragStart={() => setDraggingID(sticker.id)}
                    onDragEnd={(e) => dragEnd(e, sticker.id)}
                    src={"../assets/" + sticker.name}
                    ref={shapeRef}
                />
            </React.Fragment>
        );
    }
}

export default StickerMobile;
