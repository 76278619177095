import React, { useState } from "react";
import styled from "@emotion/styled";
import SafetyTakesEveryone from "../components/SafetyTakesEveryone";
import UploadPhoto from "../components/UploadPhoto";
import { useSelector } from "react-redux";
import HowToUseButton from "../components/HowToUseButton";
import InfoModal from "../components/InfoModal";

const HomeStyled = styled.div({
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    color: "white",
    backgroundColor: "black",
});

const Social = styled.div(({ mobileScreen }) => {
    return {
        marginTop: "40px",
    };
});

const Description = styled.div(({ mobileScreen }) => {
    return {
        textAlign: "center",
        color: "white",
        fontSize: mobileScreen ? "16px" : "16px",
        width: mobileScreen ? "300px" : "300px",
        marginTop: "40px",
    };
});

function Home() {
    const mobileScreen = useSelector((state) => state.manage.mobileScreen);
    const [showInfoModal, setShowInfoModal] = useState(false);
    return (
        <HomeStyled>
            <SafetyTakesEveryone mobileScreen={mobileScreen} />
            <Social mobileScreen={mobileScreen}>
                <img src={"./assets/social.png"} />
            </Social>
            <Description mobileScreen={mobileScreen}>
                Let's get social! Get started by uploading a photo and then adding some flair using our sticker packs.
            </Description>
            <UploadPhoto mobileScreen={mobileScreen} />
            <HowToUseButton onClick={() => setShowInfoModal(true)} />
            <InfoModal open={showInfoModal} setOpen={setShowInfoModal} />
        </HomeStyled>
    );
}

export default Home;
