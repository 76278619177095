import React, { useEffect, useState, useRef } from "react";
import { Image } from "react-konva";
import useImage from "use-image";

function CanvasEditedImageMobile({ width, height, imageObj, y, x, draggable }) {
    const src = imageObj ? imageObj.src : null;
    const [image] = useImage(src);
    const mounted = useRef(false);
    const [initialAttr, setInitialAttr] = useState(null);
    const [canDrag, setCanDrag] = useState(false);

    useEffect(() => {
        if (!initialAttr && width && height && !mounted.current) {
            const init = {
                width,
                height,
                scaleX: 1,
                scaleY: 1,
                y,
                x,
                rotation: 0,
            };
            setInitialAttr(init);
            mounted.current = true;
        }
    }, [width, height]);

    useEffect(() => {
        setCanDrag(draggable);
    }, [draggable]);

    const shapeRef = React.useRef();
    function rotateAroundPoint(shape, angleDegrees, point) {
        let angleRadians = (angleDegrees * Math.PI) / 180; // sin + cos require radians
        const x = point.x + (shape.x() - point.x) * Math.cos(angleRadians) - (shape.y() - point.y) * Math.sin(angleRadians);
        const y = point.y + (shape.x() - point.x) * Math.sin(angleRadians) + (shape.y() - point.y) * Math.cos(angleRadians);
        shape.rotation(shape.rotation() + angleDegrees); // rotate the shape in place
        shape.x(x); // move the rotated shape in relation to the rotation point.
        shape.y(y);
    }

    useEffect(() => {
        if (shapeRef.current) {
            const hammertime = new window.Hammer(shapeRef.current, { domEvents: true });
            hammertime.get("rotate").set({ enable: true });
            hammertime.get("pan").set({ direction: window.Hammer.DIRECTION_ALL });
            hammertime.get("pinch").set({ enable: true });

            let oldRotation = 0;
            let startScale = 0;
            let baseScale = 1;

            let x = shapeRef.current.x();
            let y = shapeRef.current.y();

            shapeRef.current.on("pan", function (ev) {
                let localCanDrag = false;
                setCanDrag((currentCanDrag) => {
                    localCanDrag = currentCanDrag;
                    return currentCanDrag;
                });
                if (localCanDrag) {
                    x = shapeRef.current.x();
                    y = shapeRef.current.y();
                }
            });

            shapeRef.current.on("rotatestart pinchstart", function (ev) {
                console.log("pinchstart");
                let localCanDrag = false;
                setCanDrag((currentCanDrag) => {
                    localCanDrag = currentCanDrag;
                    return currentCanDrag;
                });
                if (localCanDrag) {
                    oldRotation = ev.evt.gesture.rotation;
                    startScale = shapeRef.current.scaleX();
                    x = shapeRef.current.x();
                    y = shapeRef.current.y();
                    shapeRef.current.draggable(false);
                }
            });

            shapeRef.current.on("rotate", function (ev) {
                let localCanDrag = false;
                setCanDrag((currentCanDrag) => {
                    localCanDrag = currentCanDrag;
                    return currentCanDrag;
                });
                if (localCanDrag) {
                    if (ev.evt.gesture.rotation !== 0) {
                        let delta = oldRotation - ev.evt.gesture.rotation;
                        if (Math.abs(delta) > 181 || Math.abs(delta) < 179) {
                            oldRotation = ev.evt.gesture.rotation;
                            const point = {
                                x: ev.evt.gesture.center.x,
                                y: ev.evt.gesture.center.y,
                            };
                            rotateAroundPoint(shapeRef.current, -delta, point);
                            x = shapeRef.current.x();
                            y = shapeRef.current.y();
                        }
                    }
                }
            });

            shapeRef.current.on("pinch", function (ev) {
                console.log("pinch");
                let localCanDrag = false;
                setCanDrag((currentCanDrag) => {
                    localCanDrag = currentCanDrag;
                    return currentCanDrag;
                });
                if (localCanDrag) {
                    const newScale = baseScale * ev.evt.gesture.scale;
                    shapeRef.current.scaleX(newScale);
                    shapeRef.current.scaleY(newScale);
                    const point2 = {
                        x: ev.evt.gesture.center.x,
                        y: ev.evt.gesture.center.y,
                    };
                    const pointToX = x - point2.x;
                    const pointToY = y - point2.y;
                    let newX = point2.x + (pointToX * newScale) / startScale;
                    let newY = point2.y + (pointToY * newScale) / startScale;
                    shapeRef.current.x(newX);
                    shapeRef.current.y(newY);
                    startScale = newScale;
                    x = shapeRef.current.x();
                    y = shapeRef.current.y();
                }
            });

            shapeRef.current.on("pinchend pinchcancel", function (ev) {
                console.log("pinchend");
                let localCanDrag = false;
                setCanDrag((currentCanDrag) => {
                    localCanDrag = currentCanDrag;
                    return currentCanDrag;
                });
                if (localCanDrag) {
                    baseScale = shapeRef.current.scaleX();
                }
            });

            shapeRef.current.on("rotateend rotatecancel pinchend pinchcancel", function (ev) {
                let localCanDrag = false;
                setCanDrag((currentCanDrag) => {
                    localCanDrag = currentCanDrag;
                    return currentCanDrag;
                });
                if (localCanDrag) {
                    shapeRef.current.draggable(true);
                }
            });

            shapeRef.current.on("doubletap", function (ev) {
                x = initialAttr.x;
                y = initialAttr.y;
                shapeRef.current.setAttrs(initialAttr);
                baseScale = 1;
            });
        }
    }, [shapeRef.current]);

    if (width && height) {
        return (
            <React.Fragment>
                <Image image={image} width={width} height={height} y={y} x={x} rotation={0} draggable={canDrag} ref={shapeRef} />
            </React.Fragment>
        );
    }
    return null;
}

export default CanvasEditedImageMobile;
